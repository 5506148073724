.Game {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.Images {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: center;
	width: 100%;
	padding: 0px 10px;
	box-sizing: border-box;

	margin-bottom: 10px;
}

.Controls {
	display: flex;
	flex-direction: row;
	gap: 10px;

	margin-bottom: 20px;
}

.Guesses {
	margin: 10px 0px;
}

.Guess {
	display: flex;
	flex-direction: row;
	gap: 10px;

	color: red;
}

.Guess.Correct {
	color: #00ff00;
}

.Guess.Skipped .Text {
	font-style: italic;
}

.ResultsSection a {
	color: white;
}

.Share {
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	gap: 10px;

	font-size: 18px;

	.URL {
		color: lightblue;
	}

	.URL.Copyable {
		cursor: copy;
	}
}
