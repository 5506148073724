.DialogsRoot {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;

	background-color: transparent;
	pointer-events: none;
}

.ModalDialog {
	flex-grow: 1;
	background-color: #00000080;

	pointer-events: all;

	display: flex;
}

.ModalPosition {
	position: relative;
	flex-grow: 1;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.InstructionsDialog {
	position: relative;
	background-color: black;
	color: white;
	width: 40vw;
	max-width: 50vw;

	border: 1px solid grey;
	padding: 10px;
}

.StatsDialog {
	position: relative;
	background-color: black;
	color: white;
	width: 20vw;
	max-width: 50vw;

	border: 1px solid grey;
	padding: 10px;

	display: flex;
	align-items: center;
	flex-direction: column;
}

.Stats {
	text-align: left;
}

.CloseButton {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 18px;
	cursor: pointer;
}
