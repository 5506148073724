.App {
  text-align: center;
  position: relative;
}

.AppLogo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .AppLogo {
    animation: AppLogoSpin infinite 20s linear;
  }
}

.AppHeader {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.AppLink {
  color: #61dafb;
}

@keyframes AppLogoSpin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.InstructionsButton {
  position: absolute;
  right: 20px;
  top: 20px;

  border: 1px solid white;
  border-radius: 50%;
  width: 30px;
  height: 30px;

  font-size: 24px;
  font-weight: bold;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.StatsButton {
  position: absolute;
  top: 20px;
  right: 80px;
  height: 30px;
  width: 30px;

  cursor: pointer;
}

.PracticeModeToggle {
  position: absolute;
  left: 20px;
  top: 20px;

  font-size: 14px;
}