.ToggleElement {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
}


$toggleHeight: 20px;
$toggleWidth: 40px;

.Toggle {
	width: $toggleWidth;
	height: $toggleHeight;
	padding: 3px;
	background-color: #1e1e24;
	border-radius: 1000px;

	cursor: pointer;

	position: relative;

	display: flex;

	&.Selected {
		background-color: blue;
	}

	.SliderOuter {
		height: $toggleHeight;
		width: $toggleHeight;

		transition: transform 0.3s;

		position: relative;
	}

	.Slider {
		height: 100%;
		width: 100%;

		border-radius: 50%;
		background-color: lightblue;

	}

	&.Selected .SliderOuter {
		transform: translateX($toggleWidth - $toggleHeight );
	}
}