.ImageWrapper {
	display: none;
	flex-direction: column;
	position: relative;

	flex-basis: 23%;
	flex-grow: 0;


	&.Visible {
		display: inline-flex;
	}

	.ImageSizer {
		display: flex;
		flex-direction: row;
		max-height: 25vh;
		justify-content: center;
	}

	.Image {
		object-fit: contain;
		max-width: 100%;
		height: 100%;
		cursor: pointer;
		background: white;
	}

	&.Error {
		width: 100px;
		height: 300px;
		background-color: lightgrey;
		color: black;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.AttributionLink {
	font-size: 12px;
	cursor: pointer;
}

.Attributions {
	position: absolute;
	top: 100%;
	z-index: 50;
	background: black;
	font-size: 14px;
	padding: 10px;
	border: 1px solid darkgray;

	.AttributionLine {
		text-align: left;

		td {
			vertical-align: top;
		}

		td:first-child {
			padding-right: 10px;
		}
	}

	.Spoiler {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: black;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	a {
		color: lightblue;
	}

	a:visited {
		color: blueviolet;
	}
}

.AttributionOverlay
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background-color: #00000080;
	z-index: -1;
}


